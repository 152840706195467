/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import createGet from '../utils/create-get';
import useCurrentLanguage from '../hooks/use-current-language';

import Layout from '../components/Layout';
import Table from '../components/Table';
import List from '../components/List';
import Container from '../components/Container2';
import AspectRatioImg from '../components/AspectRatioImg';

// import List from "../components/List";
import marketsDiscoverySVG from '../img/illustrations/markets-discovery.svg';
import marketsDevelopDiagnoseSVG from '../img/illustrations/markets-develop-diagnose.svg';
import marketsDecisionSVG from '../img/illustrations/markets-decision.svg';
import marketsCommunitiesSVG from '../img/illustrations/markets-communities.svg';
import grace from '../img/illustrations/grace-ideator.svg';
import gregory from '../img/illustrations/gregory-innovator.svg';
import giselle from '../img/illustrations/giselle-sponsor.svg';
import collaboration from '../img/illustrations/collaboration.svg';

const images = {
  'markets-discovery': marketsDiscoverySVG,
  'markets-develop-diagnose': marketsDevelopDiagnoseSVG,
  'markets-decision': marketsDecisionSVG,
  'markets-communities': marketsCommunitiesSVG,
  grace,
  gregory,
  giselle,
  collaboration,
};

const CellContent = ({ title, list }) => {
  return (
    <div>
      <div sx={{ my: 3 }}>{title}</div>
      <List rounded>
        {list.map((item) => (
          <li>{item}</li>
        ))}
      </List>
    </div>
  );
};
const ModuleContent = ({ get }) => (
  <div sx={{ mx: 4 }}>
    <Styled.h2 sx={{ mt: 0, mb: 7 }}>{get('card.title')}</Styled.h2>
    <List rounded>
      {get('card.list', []).map((item) => (
        <li>{item}</li>
      ))}
    </List>
  </div>
);

const ModuleIllustration = ({
  color,
  imageKey,
  imageLabel,
  borderColor,
  borderWidth,
  bottomColor,
  width,
}) => (
  <div
    sx={{
      display: ['none', null, 'block'],
      border: 'solid',
      borderColor,
      borderWidth,
      width: 'min-content',
      height: 'min-content',
    }}
  >
    <AspectRatioImg
      src={images[imageKey]}
      sx={{ bg: color }}
      alt=""
      ratio={290 / 280}
      width={width}
    />
    <div
      sx={{
        display: ['none', null, 'flex'],
        justifyContent: 'center',
        py: 2,
        backgroundColor: bottomColor,
        pb: -borderWidth,
      }}
    >
      {imageLabel}
    </div>
  </div>
);
export const MarketsModulesPageTemplate = ({ get }) => (
  <Fragment>
    <section>
      <Container>
        <AspectRatioImg
          src={images[get('imageKey')]}
          alt=""
          ratio={912 / 256}
        />
        <section sx={{ mb: 12 }}>
          <article sx={{ display: 'flex', gap: 7, my: 13 }}>
            <ModuleIllustration
              color="#E2FAFD"
              borderColor="#9B85FB"
              borderWidth="2px"
              bottomColor="#C1C0FF"
              imageLabel={get('card.imageLabel')}
              imageKey={get('card.imageKey')}
              width={'280px'}
            />
            <ModuleContent get={get} />
          </article>
        </section>
        <section sx={{ mb: 12 }}>
          <Styled.h2>{get('table.title')}</Styled.h2>
          <Table
            headers={get('table.headers')}
            equalWidth
            rows={[
              get('table.rows', []).map((row) => (
                <CellContent
                  title={get(row, 'title')}
                  list={get(row, 'list')}
                />
              )),
            ]}
          />
        </section>
        <section sx={{ mb: 12 }}>
          <Styled.h2>{get('withGenaiz.title')}</Styled.h2>
          <div
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              backgroundColor: '#C1C0FF',
              p: 5,
              my: 7,
            }}
          >
            {get('withGenaiz.content', []).map((item) => (
              <List rounded>
                {get(item, 'list').map((i) => (
                  <li sx={{ m: 2 }}>{i}</li>
                ))}
              </List>
            ))}
          </div>
        </section>
        <section sx={{ mb: 14 }}>
          <Styled.h2>{get('tableII.title')}</Styled.h2>
          <Table
            equalWidth
            headers={get('tableII.headers')}
            rows={[
              get('tableII.rows', []).map((row) => (
                <CellContent list={get(row, 'list')} />
              )),
            ]}
          />
        </section>
        <section>
          <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {get('links', []).map((link) => (
              <Link
                to={get(link, 'path')}
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                <ModuleIllustration
                  color="gray1"
                  borderColor="gray1"
                  borderWidth="18px"
                  bottomColor="gray1"
                  imageLabel={get(link, 'imageLabel')}
                  imageKey={get(link, 'imageKey')}
                  width="250px"
                />
              </Link>
            ))}
          </div>
        </section>
      </Container>
    </section>
  </Fragment>
);

const MarketsModulePage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;
  const lang = useCurrentLanguage();

  return (
    <Layout
      page="markets"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <MarketsModulesPageTemplate lang={lang} get={createGet(frontmatter)} />
    </Layout>
  );
};

MarketsModulePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MarketsModulePage;

export const pageQuery = graphql`
  query MarketsModulePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        imageKey
        card {
          title
          list
          imageKey
          imageLabel
        }
        table {
          title
          headers
          rows {
            title
            list
          }
        }
        withGenaiz {
          title
          content {
            list
          }
        }
        tableII {
          title
          headers
          rows {
            list
          }
        }
        links {
          imageKey
          imageLabel
          path
        }
      }
    }
  }
`;
